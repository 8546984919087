import { NullOrUndefined } from '../../helper/common';

import {
    ElementTypes,
    ElementValidations,
    ComponentTypes,
    ColumnValidation,
    ValueListUrls
} from '../../config';

import { GenerateToolBars } from '../../config/element.config';
import LocationConfig from '../Location/config';
import { statusColor } from '../Order/colorConfig';
import { InvertColor, HexToRGBAFormat } from '../../helper/common';
import { appointmentStatusColor } from '../Order/colorConfig';

export const Group1 = [
    {
        useSeparator: false,
        Fields: {
            inboundLoadNumber: {
                Title: 'Load Number',
                Field: ElementTypes.LabelField()
            },
            truckNumber: {
                Title: 'Truck Number',
                Field: ElementTypes.LabelField()
            },
            carrierName: {
                Title: 'Carrier',
                Field: ElementTypes.LabelField()
            }
        }
    }
];

export const Group2 = [
    {
        useSeparator: false,
        Fields: {
            driverName: {
                Title: 'Driver',
                Field: ElementTypes.LabelField()
            },
            driverPhone: {
                Title: 'Driver Phone',
                Field: ElementTypes.Phone()
            }
        }
    }
];

export const Group3 = [
    {
        useSeparator: false,
        Fields: {
            inboundPickAppointment: {
                Title: 'Pick Appointment',
                Field: ElementTypes.DateTimeLabel()
            },
            inboundDropAppointment: {
                Title: 'Drop Appointment',
                Field: ElementTypes.DateTimeLabel()
            }
        }
    }
];

export const Group4 = [
    {
        useSeparator: false,
        Fields: {
            inbioundAppointmentStatus: {
                Title: 'Pick Appointment Status',
                Field: ElementTypes.Generic(ElementTypes.LabelField,
                    {
                        style: (_, data) => {
                            return {
                                color: InvertColor(HexToRGBAFormat(appointmentStatusColor(data), 100), true),
                                backgroundColor: appointmentStatusColor(data),
                                fontWeight: 'bold',
                                width: '100px',
                                textAlign: 'center'
                            };
                        }
                    })
            },
            dropInboundAppointmentStatus: {
                Title: 'Drop Appointment Status',
                Field: ElementTypes.Generic(ElementTypes.LabelField,
                    {
                        style: (_, data) => {
                            return {
                                color: InvertColor(HexToRGBAFormat(appointmentStatusColor(data), 100), true),
                                backgroundColor: appointmentStatusColor(data),
                                fontWeight: 'bold',
                                width: '100px',
                                textAlign: 'center'
                            };
                        }
                    })
            }
        }
    }
];

export const Group5 = [
    {
        useSeparator: false,
        Fields: {
            inboundIn: {
                Title: 'Check In',
                Field: ElementTypes.DateTimeLabel()
            },
            inboundOut: {
                Title: 'Check Out',
                Field: ElementTypes.DateTimeLabel()
            }
        }
    }
];

export const OutboundGroup1 = [
    {
        useSeparator: false,
        Fields: {
            outboundLoadNumber: {
                Title: 'Load Number',
                Field: ElementTypes.LabelField()
            },
            outboundTruckNumber: {
                Title: 'Truck Number',
                Field: ElementTypes.LabelField()
            },
            outboundCarrierName: {
                Title: 'Carrier',
                Field: ElementTypes.LabelField()
            }
        }
    }
];

export const OutboundGroup2 = [
    {
        useSeparator: false,
        Fields: {
            outboundDriverName: {
                Title: 'Driver Name',
                Field: ElementTypes.LabelField()
            },
            outboundDriverPhone: {
                Title: 'Driver Phone',
                Field: ElementTypes.Phone()
            }
        }
    }
];

export const OutboundGroup3 = [
    {
        useSeparator: false,
        Fields: {
            outboundPickAppointment: {
                Title: 'Pick Appointment',
                Field: ElementTypes.DateTimeLabel()
            },
            outboundDropAppointment: {
                Title: 'Drop Appointment',
                Field: ElementTypes.DateTimeLabel()
            }
        }
    }
];

export const OutboundGroup4 = [
    {
        useSeparator: false,
        Fields: {
            pickOutboundAppointmentStatus: {
                Title: 'Pick Appointment Status',
                Field: ElementTypes.Generic(ElementTypes.LabelField,
                    {
                        style: (_, data) => {
                            return {
                                color: InvertColor(HexToRGBAFormat(appointmentStatusColor(data), 100), true),
                                backgroundColor: appointmentStatusColor(data),
                                fontWeight: 'bold',
                                width: '100px',
                                textAlign: 'center'
                            };
                        }
                    })
            },
            outboundAppointmentStatus: {
                Title: 'Drop Appointment Status',
                Field: ElementTypes.Generic(ElementTypes.LabelField,
                    {
                        style: (_, data) => {
                            return {
                                color: InvertColor(HexToRGBAFormat(appointmentStatusColor(data), 100), true),
                                backgroundColor: appointmentStatusColor(data),
                                fontWeight: 'bold',
                                width: '100px',
                                textAlign: 'center'
                            };
                        }
                    })
            }
        }
    }
];

export const OutboundGroup5 = [
    {
        useSeparator: false,
        Fields: {
            outboundIn: {
                Title: 'Check In',
                Field: ElementTypes.DateTimeLabel()
            },
            outboundOut: {
                Title: 'Check Out',
                Field: ElementTypes.DateTimeLabel()
            }
        }
    }
];

const OrderForm = {
    Title: 'Order',
    Resource: 'Order/loadorderitem',
    Master: 'Order',
    Key: 'id',
    IsRest: true,
    Form: {
        Title: 'Order',
        SectionGroups: [
            {
                Sections: [
                    {
                        classes: 'wide',
                        Fields: {
                            Group1: [],
                            Group2: [],
                            Group3: [],
                            Group4: [],
                            Group5: [],
                            customerCodeOrName: {
                                Title: 'Customer',
                                Field: ElementTypes.LabelField()
                            },
                            pickLocation: {
                                Title: 'Pick Location',
                                Field: ElementTypes.LabelField()
                            },
                            Group6: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        dropLocationId: {
                                            Title: 'DEL WH',
                                            Field: ElementTypes.Generic(ElementTypes.ComboAddViewOrEditWithCash, {
                                                dataSource: ValueListUrls.Location,
                                                addparams: {
                                                    TitlePrefix: 'Drop ',
                                                    PopupConfig: LocationConfig
                                                },
                                                selectedValueType: 'drop2WH',
                                                editability: o => o.batch > 1,
                                                condition: o => o.batch > 1,
                                                display: o => o.dropLocation
                                            }),
                                            Validations: [ElementValidations.Required()],
                                            Dependencies: [
                                                ['dropRegionCode', 'outboundRegionCode'],
                                                ['dropRegionName', 'outboundRegionName'],
                                                ['dropRegionId', 'outboundRegionId'],
                                                ['drop2State', 'locationStateCode'],
                                                ['drop2City', 'city']
                                            ]
                                        },
                                        drop2City: {
                                            Title: 'DEL CITY',
                                            Field: ElementTypes.LabelField()
                                          
                                        },
                                        drop2State: {
                                            Title: 'DEL ST',
                                            Field: ElementTypes.LabelField()
                                            
                                        },
                                    }
                                }
                            ],
                            Group7: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        pickRegionName: {
                                            Title: 'Pick Region',
                                            Field: ElementTypes.LabelField()
                                        },
                                        //dropRegionId: {
                                        //    Title: 'Drop Region',
                                        //    Field: ElementTypes.Generic(ElementTypes.ComboViewOrEdit, {
                                        //        dataSource: '/api/region/valuelistempty',
                                        //        editability: o => o.batch > 1,
                                        //        condition: o => o.batch > 1,
                                        //        display: o => o.dropRegionName
                                        //    })
                                        //},
                                        batchType: {
                                            Title: 'Batch',
                                            Field: ElementTypes.Generic(ElementTypes.LabelField)
                                        },
                                        orderStatus: {
                                            Title: 'Order Cycle',
                                            Field: ElementTypes.Generic(ElementTypes.LabelField,
                                                {
                                                    style: (_, data) => {
                                                        return {
                                                            color: InvertColor(HexToRGBAFormat(statusColor(data), 100), true),
                                                            backgroundColor: statusColor(data),
                                                            fontWeight: 'bold',
                                                            textAlign: 'center'
                                                        };
                                                    }
                                                }
                                            )
                                        }
                                    }
                                }
                            ]
                        }
                    },
                    {
                        Fields: {
                            Group1: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        puDate: {
                                            Title: 'PU DATE',
                                            Field: ElementTypes.DateField(),
                                            Dependencies: [['pickWeek', 'weekNumber']]
                                        }
                                        //referenceNumber: {
                                        //    Title: 'Row',
                                        //    Field: ElementTypes.LabelField(),
                                        //    Width: 60
                                        //}
                                    }
                                }
                            ],
                            dueDate: {
                                Title: 'DUE DATE',
                                Field: ElementTypes.DateField()
                            },
                            dropDate: {
                                Title: 'DEL DATE',
                                Field: ElementTypes.DateField(),
                                Dependencies: [['dropWeek', 'weekNumber']]
                            },
                            Group2: [
                                {
                                    useSeparator: false,
                                    Fields: {
                                        poNumber: {
                                            Title: 'PO#',
                                            Field: ElementTypes.TextField()
                                        },
                                        soNumber: {
                                            Title: 'SO#',
                                            Field: ElementTypes.TextField()
                                        },
                                    }
                                }
                            ],
                            routeId: {
                                Title: 'Route',
                                Field: ElementTypes.Generic(ElementTypes.SelectViewOrEdit, {
                                    dataSource: [{ "value": 1, "text": "WHS" }, { "value": 2, "text": "DIR" }, { "value": 3, "text": "Both" }],
                                    editability: o => o.batch > 1,
                                    condition: o => o.batch > 1,
                                    display: o => o.routeId === 1 ? "WHS" : o.routeId === 2 ? "DIR" : "Both"
                                })
                            }
                        }
                    },
                    {
                        Fields: {
                            palletCount: {
                                Title: 'PAL',
                                Attributes: {
                                    wrap: {
                                        purple: (o, data) => {
                                            if (o && NullOrUndefined(data)) {
                                                return o.space < data ? '' : null;
                                            }

                                            return null;
                                        }
                                    }
                                },
                                Field: ElementTypes.Generic(ElementTypes.IntFieldViewOrEdit, {
                                    editability: o => o.batch > 1,
                                    condition: o => o.batch > 1
                                }),
                                Messages: ['_pallets'],
                                Validations: [
                                    ElementValidations.Required(),
                                    ElementValidations.IntField(),
                                    ElementValidations.MaxValue(30)
                                ]
                            },
                            space: {
                                Title: 'SPA',
                                Field: ElementTypes.IntField(),
                                Messages: ['_space'],
                                Validations: [
                                    ElementValidations.Required(),
                                    ElementValidations.IntField(),
                                    ColumnValidation.MaxValue(
                                        'Space Count Exceeded that of the Pallet Count.\nPlease adjust Space entry',
                                        'space',
                                        '>',
                                        'palletCount'
                                    )
                                ]
                            },
                            weight: {
                                Title: 'LBS',
                                Field: ElementTypes.Generic(ElementTypes.DecimalFieldViewOrEdit, {
                                    editability: o => o.batch > 1,
                                    condition: o => o.batch > 1
                                }),
                                Messages: ['_weight'],
                                Validations: [
                                    ElementValidations.PositiveField(),
                                    ColumnValidation.MaxValue(
                                        '1 Pallet = Maximum weight of 2750 pounds\nWeight Exceeded that of the Pallet Weight Limit.\nPlease adjust Weight Entry',
                                        'weight',
                                        '>',
                                        'palletCount'
                                    ),
                                    ElementValidations.MaxValue(42800)
                                ]
                            },
                            cases: {
                                Title: 'Cases',
                                Field: ElementTypes.IntDecimalField(),
                                Validations: [ElementValidations.PositiveField()]
                            },
                            price: {
                                Title: 'Price',
                                Field: ElementTypes.Generic(ElementTypes.CurrencyFieldViewOrEdit, {
                                    editability: (o) => o['batch'] === 3,
                                    condition: o => o.batch > 1
                                })
                            },
                            inboundLoadingSequenceName: {
                                Title: 'Inbound Loading Sequence',
                                Field: ElementTypes.LabelField()
                            },
                            inboundLoadingDescription: {
                                Title: 'Inbound Loading Description ',
                                Field: ElementTypes.LabelField(o => o && o['inboundLoadingSequenceId'] === 5)
                            },
                            outboundLoadingSequenceName: {
                                Title: 'Outbound Loading Sequence',
                                Field: ElementTypes.LabelField()
                            },
                            outboundLoadingDescription: {
                                Title: 'Outbound Loading Description ',
                                Field: ElementTypes.LabelField(o => o && o['inboundLoadingSequenceId'] === 5)
                            }
                        }
                    }
                ]
            },
            {
                Components: {
                    attachments: {
                        Component: ComponentTypes.Attachments('guid', {
                            List: {
                                Columns: {
                                    guid: {
                                        Title: 'Download',
                                        Field: ElementTypes.Download('fileName'),
                                        Width: 80,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    fileName: {
                                        Title: 'File Name',
                                        Field: ElementTypes.TextField(),
                                        Validations: [ElementValidations.Required()],
                                        Width: 250,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    originalFileName: {
                                        Title: 'Original File Name',
                                        Field: ElementTypes.TableLabelField(),
                                        Width: 250,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    size: {
                                        Title: 'File Size',
                                        Field: ElementTypes.Generic(ElementTypes.TableFileSizeLabelField),
                                        Width: 80,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    createDate: {
                                        Title: 'Created Date',
                                        Field: ElementTypes.ColumnDate((o, data) => o && data),
                                        Width: 80,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    fullName: {
                                        Title: 'Created By',
                                        Field: ElementTypes.TableLabelField(),
                                        Width: 80,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    },
                                    external: {
                                        Title: 'External',
                                        Field: ElementTypes.ColumnBool(),
                                        Width: 60,
                                        Attributes: {
                                            wrap: {
                                                left: ''
                                            }
                                        }
                                    }
                                }
                            },
                            Popup: {
                                Title: 'Attachment Comment',
                                SectionGroups: [
                                    {
                                        Sections: [
                                            {
                                                Fields: {
                                                    fileName: {
                                                        Title: 'File Name',
                                                        Field: ElementTypes.LabelField()
                                                    },
                                                    originalFileName: {
                                                        Title: 'Original File Name',
                                                        Field: ElementTypes.LabelField()
                                                    },
                                                    size: {
                                                        Title: 'File Size',
                                                        Field: ElementTypes.FileSizeLabelField()
                                                    },
                                                    external: {
                                                        Field: ElementTypes.CheckBox('External Document')
                                                    },
                                                    comments: {
                                                        Title: 'File Comment',
                                                        Field: ElementTypes.Comment(),
                                                        Focus: true
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                ],
                                ToolBars: GenerateToolBars(['Save', 'Cancel'])
                            }
                        })
                    }
                }
            },
            {
                Components: {
                    comments: {
                        Component: ComponentTypes.CommentsSheet('id', {
                            createDate: {
                                Title: 'Date Time',
                                Field: ElementTypes.ColumnDateTime((o, data) => o && data),
                                Width: 140
                            },
                            user: {
                                Title: 'Created By',
                                Field: ElementTypes.TableLabelField(),
                                Width: 150,
                                Attributes: {
                                    wrap: {
                                        left: ''
                                    }
                                }
                            },
                            comment: {
                                Title: 'Comment',
                                Field: ElementTypes.TableLabelField(),
                                Attributes: {
                                    wrap: {
                                        left: ''
                                    }
                                }
                            }
                        },
                            'table_view extra')
                    }
                }
            },
            {
                Sections: [
                    {
                        classes: 'wide',
                        Fields: {
                            comment: {
                                Title: 'Comment',
                                Field: ElementTypes.Comment(),
                                Attributes: {
                                    textarea: {
                                        note: ''
                                    }
                                }
                            }
                        }
                    }
                ]
            }
        ],
        ToolBars: GenerateToolBars(['SaveClose', 'Cancel'])
    }
};

export default OrderForm;