import axios from 'axios';
import store from '../../../store';
import * as actionTypes from './loadActionTypes';

const buildUrl = (resource, method = '') => `/api/${resource}${method ? `/${method}` : ''}`;
const buildSimpleUrl = endPoint => `/api/${endPoint}`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const load = (resource, loadingType) => dispatch => {
    if (loadingType !== 'withoutLoading') {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_IN_LOADING) });
    };
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_REFRESH_IN_PROCESS), data: true });
    axios.get(buildUrl(resource, 'area'),
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
        }).finally(() => {
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_REFRESH_IN_PROCESS), data: false });
        });
};

export const manifestList = resource => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_IN_LOADING) });
    axios.get(buildUrl('load', resource),
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_MANIFEST_LIST), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
        });
};

export const reactOnAssignedItem = (resource, id, type) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.REACTED_ASSIGNED), data: { id, type } });

export const reactOnAvailableItem = (resource, id, type) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.REACTED_AVAILABLE), data: { id, type } });

export const reactOnAvailableItems = (resource, ids, type) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.REACTED_AVAILABLES), data: { ids, type } });

export const reactOnAssignedItems = (resource, ids, type) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.REACTED_ASSIGNEDS), data: { ids, type } });

export const unreactOrders = resource =>
    ({ type: actionTypes.getActionType(resource, actionTypes.UNREACT_ORDERS)});

export const setHeaderWidth = (resource, ind, width) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH), data: { ind, width } });

export const unAssignOrders = (resource, orders, allAvailableOrders) => dispatch => {
    const orderGroupIndex = orders[0]?.rowState?.orderData?.orderGroupIndex;
    const allHardBatchOrders = allAvailableOrders?.filter(order => order.orderGroupIndex === orderGroupIndex && order.batchType === 'Hard');
    const orderIds = allHardBatchOrders?.map(order => order.id);

    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.UNASSIGN_ORDERS), 
        data: allHardBatchOrders.length ? allHardBatchOrders : orders.map(o => o.rowState.orderData)
    });
    axios.put(buildUrl(resource, 'unassignorders'),
        {
            orderIds: orderIds.length ? orderIds : orders.map(i => i.rowState.orderData.id),
        },
        axiosConfig()
    ).then(response => {
        const errorList = response.data.filter(d => d.hasError);
        const errorMessage = response.data?.message || response.data[0]?.message;
        dispatch(errorAction(resource, errorList.length > 0, errorMessage));
        errorList.forEach(e => console.error(e.message));
        // dispatch(reloadAfterUpdate(resource, 'area'));
    }).catch(err => {
        dispatch(errorAction(resource, true));
        console.error(err);
        console.log(err.response);
    });
};

export const addNewLoad = resource => dispatch => {

    dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_ADD_NEW_IN_PROCESS), data: true });

    axios.post(buildUrl(resource),
        {
            id: 0
        },
        axiosConfig()
    )
        .then(response => {
            if (response.data.hasError) {
                dispatch(errorAction(resource, true));
                console.error(response.data.message);
            }
            else {
                dispatch(
                    {
                        type: actionTypes.getActionType(resource, actionTypes.ADD_NEW_LOAD),
                        data: {
                            loadData: response.data
                        }
                    });
            }
        })
        .catch(err => {
            dispatch(errorAction(resource, true));
            console.error(err);
            console.log(err.response);
        }).finally(() => {
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_ADD_NEW_IN_PROCESS), data: false });
        });
};

export const assignOrdersToLoad = (resource, load, orders, allAvailableOrders, newLoad) => dispatch => {
    const pickAppointment = resource === 'load' ? 'inboundPickAppointment': 'outboundDropAppointment';
    const pickAppointmentStatus = resource === 'load' ? 'inboundAppointmentStatus': 'outboundAppointmentStatus';
    const location = resource === 'load' ? 'pickLocation': 'dropLocation';

    const hardBatchOrders = orders.filter(order =>  order.rowState.orderData.batchType === 'Hard');
    const notHardBatchStateOrders = orders.filter(order =>  order.rowState.orderData.batchType !== 'Hard');
    const notHardBatchOrders = notHardBatchStateOrders.map(order =>  order.rowState.orderData);
    const orderGroupIndexes = hardBatchOrders?.map(order => order.rowState?.orderData?.orderGroupIndex);

    let allHardBatchOrders = [];
    for (let i = 0; i < allAvailableOrders.length; i++) {
        for (let j = 0; j < orderGroupIndexes.length; j++) {
            if (allAvailableOrders[i].orderGroupIndex === orderGroupIndexes[j]) {
                allHardBatchOrders.push(allAvailableOrders[i])
            };
        };
    };

    const allSelectedOrders = [...allHardBatchOrders, ...notHardBatchOrders]
    const orderIds = allSelectedOrders?.map(order => order.id);
    const ordersLocationsList = [ ...new Set(orders.map(order => order.rowState.orderData[location]))];
    const locationGroupsList = [];
    const locationGroupsObjectList = [];

    for (const loc of ordersLocationsList) {
        locationGroupsList.push(orders.filter(order => order.rowState.orderData[location] === loc));
    }
    
    locationGroupsList.forEach(locationGroup => {
        const locationGroupsObject = {
            locationOfGroup: '',
            orders: [],
            showModal: false,
        };
    
        const locationOfGroup = locationGroup[0].rowState.orderData[location];
        locationGroupsObject.locationOfGroup = locationOfGroup;
        locationGroupsObject.orders = locationGroup;


        // START
        if (locationGroup.length > 1) {
            const haveAtLeastOneOrderWithAppt = locationGroup.filter(ord => ord.rowState.orderData[pickAppointment]).length;
            const haveAtLeastOneOrderWithOutAppt = locationGroup.filter(ord => !ord.rowState.orderData[pickAppointment]).length;
            const noNeedModal = haveAtLeastOneOrderWithAppt && haveAtLeastOneOrderWithOutAppt;
            if (noNeedModal) {
                if (([...new Set(locationGroup.map(order => order.rowState.orderData[pickAppointment]))].filter(order => order !== null).length > 1)) {
                    locationGroupsObject.showModal = true;
                }
            } else if ( ([...new Set(locationGroup.map(order => order.rowState.orderData[pickAppointment]))].filter(order => order !== null).length > 1 
                || [...new Set(locationGroup.map(order => Number(order.rowState.orderData[pickAppointmentStatus])))].filter(order => order !== null).length > 1)) {
                locationGroupsObject.showModal = true;
            }
        }
        // END
        locationGroupsObjectList.push(locationGroupsObject);
    });

    let noAssignedLocation = false;
    load.ordersData.forEach(order => {
        if (!ordersLocationsList.includes(order.rowState.orderData?.[location]) && 
            orders.length > 1 &&
            new Set(orders.map(order => order?.[location])).length > 1) {
                noAssignedLocation = true;
            }
        })
        
    // case when assigned orders are many and the locations are different
    const openModal = locationGroupsObjectList.filter(group => group.showModal).length > 0 || noAssignedLocation;

    let data = {
        orders: allSelectedOrders.length ? allSelectedOrders : orders.map(o => o.rowState.orderData),
        loadData: load,
        openModal: false,
    };

    if (openModal) {
        data.openModal = true;
    };

    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.ASSIGN_ORDERS_TO_LOAD),
        data: data
    });

    if (!openModal) {
        axios.put(buildUrl(resource, 'assignorders'),
            {
                loadId: load.rowState.loadData.id,
                orderIds: orderIds.length ? orderIds : orders.map(i => i.rowState.orderData.id),
            },
            axiosConfig()
        )
        .then(response => {
            const hasError = response.data.hasError || response.data[0].hasError;
            const errorMessage = response.data.message || response.data[0].message;
            if (hasError) {
                dispatch(errorAction(resource, hasError, errorMessage));
            } else {
                dispatch(reloadAfterUpdate(resource, 'area'))
            }
        })
        .catch(err => {
            console.log(err)
            dispatch(errorAction(resource, true));
            console.error(err);
            console.log(err.response);
        });
    } 
};

export const assignOrdersToLoadAndUpdate = (resource, load, orders, allAvailableOrders, closeModal, locationObjects) => dispatch => {
    const hardBatchOrders = orders.filter(order =>  order.rowState.orderData.batchType === 'Hard');
    const notHardBatchStateOrders = orders.filter(order =>  order.rowState.orderData.batchType !== 'Hard');
    const notHardBatchOrders = notHardBatchStateOrders.map(order =>  order.rowState.orderData);
    const orderGroupIndexes = hardBatchOrders?.map(order => order.rowState?.orderData?.orderGroupIndex);
    const location = resource === 'load' ? 'pickLocation': 'dropLocation';

    let allHardBatchOrders = [];
    for (let i = 0; i < allAvailableOrders.length; i++) {
        for (let j = 0; j < orderGroupIndexes.length; j++) {
            if (allAvailableOrders[i].orderGroupIndex === orderGroupIndexes[j]) {
                allHardBatchOrders.push(allAvailableOrders[i])
            };
        };
    };
    
    const allSelectedOrders = [...allHardBatchOrders, ...notHardBatchOrders];
    const ordersLocationsList = [ ...new Set(orders.map(order => order.rowState.orderData?.[location]))];
    const locationGroups = [];

    for (const loc of ordersLocationsList) {
        locationGroups.push(orders.filter(order => order.rowState.orderData?.[location] === loc));
    }

    let data = {
        orders: allSelectedOrders.length ? allSelectedOrders : orders.map(o => o.rowState.orderData),
        loadData: load,
        openModal: false,
        locationObjects: locationObjects,
    };

    const getAllOrderIdsFromGroup = orderIds => {
        const orders = allAvailableOrders.filter(order => orderIds.includes(order.id));
        const hardBatchOrders = orders.filter(order => order.batchType === 'Hard');
        const notHardBatchStateOrders = orders.filter(order =>  order.batchType !== 'Hard');
        const orderGroupIndexes = hardBatchOrders?.map(order => order.orderGroupIndex);
        let allHardBatchOrders = [];
        for (let i = 0; i < allAvailableOrders.length; i++) {
            for (let j = 0; j < orderGroupIndexes.length; j++) {
                if (allAvailableOrders[i].orderGroupIndex === orderGroupIndexes[j]) {
                    allHardBatchOrders.push(allAvailableOrders[i])
                };
            };
        };
        const allSelectedOrders = [...allHardBatchOrders, ...notHardBatchStateOrders];
        const allSelectedOrdersIds = allSelectedOrders.map(order => order?.id);
        return allSelectedOrdersIds;
    }

    if (closeModal) {
        data.openModal = false;
    };

    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.ASSIGN_ORDERS_TO_LOAD),
        data: data
    });

    if (closeModal === 'cancelModal') {
        dispatch(unAssignOrders(resource, orders, allAvailableOrders))
        dispatch(reloadAfterUpdate(resource, 'area'));
    }

    if (!closeModal) {
        const items = locationObjects.map(locationGroup => {
            return {
                orderids: getAllOrderIdsFromGroup(locationGroup.orderIds),
                appointmentStatus: locationGroup.checkedStatus ? locationGroup.checkedStatus : 0,
                appointment: locationGroup.checkedAppointment,
            }
        });

        axios.put(buildUrl(resource, 'changeappointment'), {
                items: items,
                loadId: load.rowState.loadData.id,
            },
            axiosConfig()
        )
            .then(response => {
                const hasError = response.data?.hasError || response.data[0]?.hasError;
                const errorMessage = response.data?.message || response.data[0]?.message;
                if (hasError) {
                    dispatch(errorAction(resource, hasError, errorMessage));
                } else {
                    dispatch(reloadAfterUpdate(resource, 'area'))
                }
            })
            .catch(err => {
                dispatch(errorAction(resource, true));
                console.error(err);
                console.log(err.response);
            });
        }
};

export const assignOrdersToLoadAndUpdateNewLoad = (resource, orders, allAvailableOrders, closeModal, locationObjects) => dispatch => {
    const hardBatchOrders = orders.filter(order =>  order.batchType === 'Hard');
    const notHardBatchStateOrders = orders.filter(order =>  order.batchType !== 'Hard');
    const orderGroupIndexes = hardBatchOrders?.map(order => order.orderGroupIndex);
    const location = resource === 'load' ? 'pickLocation': 'dropLocation';

    let allHardBatchOrders = [];
    for (let i = 0; i < allAvailableOrders.length; i++) {
        for (let j = 0; j < orderGroupIndexes.length; j++) {
            if (allAvailableOrders[i].orderGroupIndex === orderGroupIndexes[j]) {
                allHardBatchOrders.push(allAvailableOrders[i])
            };
        };
    };
    
    if (allHardBatchOrders.length) {
        for (let i = 0; i < locationObjects.length; i++) {
            locationObjects[i].orderIds.forEach(id => {
                const order = allAvailableOrders.find(o => o.id === id);
                const allHardBatchOrderIds = allHardBatchOrders.map(o => o.id);
                if (order && allHardBatchOrderIds.includes(id)) {
                    const index = order.orderGroupIndex;
                    const allOrdersByIndex = allAvailableOrders.filter(order => order.orderGroupIndex === index);
                    locationObjects[i].orderIds = allOrdersByIndex.map(order => order.id);
                }
            })
        };
    };

    const allSelectedOrders = [...allHardBatchOrders, ...notHardBatchStateOrders];
    const ordersLocationsList = [ ...new Set(orders.map(order => order?.[location]))];
    const locationGroups = [];

    for (const loc of ordersLocationsList) {
        locationGroups.push(orders.filter(order => order?.[location] === loc));
    }

    let data = {
        orders: allSelectedOrders.length ? allSelectedOrders : orders.map(o => o.rowState.orderData),
        loadData: load,
        openModal: false,
        locationObjects: locationObjects,
    };

    if (closeModal) {
        data.openModalNewLoad = false;
    };

    // dispatch({
    //     type: actionTypes.getActionType(resource, actionTypes.ASSIGN_ORDERS_TO_LOAD),
    //     data: data
    // });

    if (closeModal === 'cancelModal') {
        // dispatch(unAssignOrders(resource, orders, allAvailableOrders))
        dispatch(reloadAfterUpdate(resource, 'area'));
    }

    if (!closeModal) {
        const items = locationObjects.map(locationGroup => {
            return {
                orderids: locationGroup.orderIds,
                appointmentStatus: locationGroup.checkedStatus ? locationGroup.checkedStatus : 0,
                appointment: locationGroup.checkedAppointment,
            }
        });

        axios.put(buildUrl(resource, 'changeappointment'), {
                items: items,
                loadId: 0,
            },
            axiosConfig()
        )
            .then(response => {
                const hasError = response.data.hasError;
                const error = response.data.message;
                if (hasError) {
                    dispatch(errorAction(resource, hasError, error));
                } else {
                    dispatch(reloadAfterUpdate(resource, 'area'))
                }
            })
            .catch(err => {
                dispatch(errorAction(resource, true));
                console.error(err);
                console.log(err.response);
            });
        }
};

export const deleteLoad = (resource, rowState) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELETE_LOAD), data: { rowState } });
    axios.delete(buildUrl(resource),
        {
            headers: axiosConfig().headers,
            params: {
                id: rowState.loadId,
            }
        })
        .then(response => {
            if (response.data.hasError) {
                dispatch(errorAction(resource, true));
                console.error(response.data.message);
            }
            if (rowState.isSub) {
                dispatch(reloadAfterUpdate(resource, 'area'));
            }
        }).catch(err => {
            dispatch(errorAction(resource, true));
            console.error(err);
            console.log(err.response);
        });
};

export const createSubLoadAssignOrders = (resource, orders, dropLocationId) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CREATE_SUB_IN_PROCESS), data: true });
    axios.put(buildUrl(resource, 'createsubloadassignorders'),
        {
            loadId: orders[0].rowState.loadData.id,
            dropLocationId,
            orderIds: orders.length > 0 ? orders.map(i => i.rowState.orderObjectId) : []
        },
        axiosConfig()
    )
        .then(response => {
            if (response.data.hasError) {
                dispatch(errorAction(resource, true));
                console.error(response.data.message);
            }
            else {
                //dispatch(
                //    {
                //        type: actionTypes.CREATE_SUB_LOAD_ASSIGN_ORDERS,
                //        data: {
                //            orders,
                //            loadData: response.data,
                //            dropLocationId
                //        }
                //    });
                dispatch(load(resource));
            }
        })
        .catch(err => {
            dispatch(errorAction(resource, true));
            console.error(err);
            console.log(err.response);
        }).finally(() => {
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CREATE_SUB_IN_PROCESS), data: false });
        });
};

export const assignOrdersToNewLoad = (resource, orders, index, allAvailableOrders) => dispatch => {
    const pickAppointment = resource === 'load' ? 'inboundPickAppointment': 'outboundDropAppointment';
    const pickAppointmentStatus = resource === 'load' ? 'inboundAppointmentStatus': 'outboundAppointmentStatus';
    const location = resource === 'load' ? 'pickLocation': 'dropLocation';

    const hardBatchOrders = orders.filter(order =>  order.rowState.orderData.batchType === 'Hard');
    const notHardBatchStateOrders = orders.filter(order =>  order.rowState.orderData.batchType !== 'Hard');
    const notHardBatchOrders = notHardBatchStateOrders.map(order =>  order.rowState.orderData);
    const orderGroupIndexes = hardBatchOrders?.map(order => order.rowState?.orderData?.orderGroupIndex);

    let allHardBatchOrders = [];
    for (let i = 0; i < allAvailableOrders.length; i++) {
        for (let j = 0; j < orderGroupIndexes.length; j++) {
            if (allAvailableOrders[i].orderGroupIndex === orderGroupIndexes[j]) {
                const assignedOrderWithHardType = orders.filter(order => order.rowState.orderData.id === allAvailableOrders[i].id)[0];
                if (assignedOrderWithHardType) {
                    allHardBatchOrders.push(assignedOrderWithHardType.rowState.orderData)
                }
            };
        };
    };
    
    const allSelectedOrders = [...allHardBatchOrders, ...notHardBatchOrders]
    const ordersLocationsList = [ ...new Set(orders.map(order => order.rowState.orderData?.[location]))];
    const locationGroupsList = [];
    const locationGroupsObjectList = [];

    for (const loc of ordersLocationsList) {
        locationGroupsList.push(orders.filter(order => order.rowState.orderData?.[location] === loc));
    }

    locationGroupsList.forEach(locationGroup => {
        const locationGroupsObject = {
            locationOfGroup: '',
            orders: [],
            showModal: false,
        };
    
        const locationOfGroup = locationGroup[0].rowState.orderData?.[location];
        locationGroupsObject.locationOfGroup = locationOfGroup;
        locationGroupsObject.orders = locationGroup;

        if (locationGroup.length > 1) {
            const haveAtLeastOneOrderWithAppt = locationGroup.filter(ord => ord.rowState.orderData[pickAppointment]).length;
            const haveAtLeastOneOrderWithOutAppt = locationGroup.filter(ord => !ord.rowState.orderData[pickAppointment]).length;
            const noNeedModal = haveAtLeastOneOrderWithAppt && haveAtLeastOneOrderWithOutAppt;
            if (noNeedModal) {
                if (([...new Set(locationGroup.map(order => order.rowState.orderData[pickAppointment]))].filter(order => order !== null).length > 1)) {
                    locationGroupsObject.showModal = true;
                }
            } else if ( ([...new Set(locationGroup.map(order => order.rowState.orderData[pickAppointment]))].filter(order => order !== null).length > 1 
                || [...new Set(locationGroup.map(order => Number(order.rowState.orderData[pickAppointmentStatus])))].filter(order => order !== null).length > 1)) {
                locationGroupsObject.showModal = true;
            }
        }

        locationGroupsObjectList.push(locationGroupsObject);
    });

    const openModalNewLoad = locationGroupsObjectList.filter(group => group.showModal).length > 0;

    let data = {
        orders: allSelectedOrders.length ? allSelectedOrders : orders.map(o => o.rowState.orderData),
        openModalNewLoad: false,
    };

    if (openModalNewLoad) {
        data.openModalNewLoad = true;
    };

    if (openModalNewLoad) {
        dispatch({
            type: actionTypes.getActionType(resource, actionTypes.ASSIGN_ORDERS_TO_NEW_LOAD_WITH_MODAL),
            data: data
        });
    }

    if (!openModalNewLoad) {
        const orderIds = [];
        const allOrders = [];
        for (let i = 0; i < orders.length; i++) {
            const orderGroupIndex = orders[i]?.rowState?.orderData?.orderGroupIndex;
            const hardBatchOrders = allAvailableOrders?.filter(order => order.orderGroupIndex === orderGroupIndex && order.batchType === 'Hard');
            if (!hardBatchOrders?.length) {
                orderIds.push(orders[i]?.rowState?.orderData?.id);
                allOrders.push(orders[i]?.rowState?.orderData);
            } else {
                const allHardBatchOrderIds = hardBatchOrders?.map(order => order.id);
                allHardBatchOrderIds.length && orderIds.push(...allHardBatchOrderIds);
                hardBatchOrders?.length && allOrders.push(...hardBatchOrders);
            }
        }

        dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CREATE_IN_PROCESS), data: true });
        axios.put(buildUrl(resource, 'createloadassignorders'),
            {
                loadId: 0,
                orderIds: orderIds?.length ? orderIds : orders.map(i => i.rowState.orderData.id),
            },
            axiosConfig())
            .then(response => {
                if (response.data.hasError) {
                    dispatch(errorAction(resource, true));
                    console.error(response.data.message);
                }
                else {
                    dispatch(
                        {
                            type: actionTypes.getActionType(resource, actionTypes.ASSIGN_ORDERS_TO_NEW_LOAD),
                            data: {
                                orders: allOrders?.length ? allOrders : orders.map(o => o.rowState.orderData),
                                index,
                                loadData: response.data
                            }
                        });
                }
            })
            .catch(err => {
                dispatch(errorAction(resource, true));
                console.error(err);
                console.log(err.response);
            }).finally(() => {
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_CREATE_IN_PROCESS), data: false });
            });
        }
};

export const setPopupItemData = (resource, value, rowState, header) => dispatch => {
    
        dispatch({
            type: actionTypes.getActionType(resource, actionTypes.UPDATE_EFS_DATA), data: {
                value,
            rowState,
            header
        }
    });
};

export const setItemData = (resource, value, rowState, header) => dispatch => {
    if (rowState.type === 'load') {
        dispatch({
            type: actionTypes.getActionType(resource, actionTypes.SET_LOAD_DATA), data: {
                value,
                rowState,
                header
            }
        });
        if (header.key === 'wk') {
            rowState.loadData['pickWeek'] = value;
        }
        axios.put(buildUrl(resource, 'loadlintitem'), rowState.loadData,
            axiosConfig())
            .then(response => {
                if (response.data.hasError) {
                    dispatch(errorAction(resource, true));
                    console.error(response.data.message);
                }
                const headerForReload = ["wk"];
                const needReload = headerForReload.includes(header?.key);
                if (needReload) {
                    dispatch(reloadAfterUpdate(resource, 'area'));
                }
            }).catch(err => {
                dispatch(errorAction(resource, true));
                console.error(err);
                console.log(err.response);
            });
    }
    else {
        dispatch({
            type: actionTypes.getActionType(resource, actionTypes.SET_DATA), data: {
                value: header?.key === 'outboundLumper' ? '' : value,
                rowState,
                header
            }
        });
        if (rowState.orderData && !(header?.key === 'outboundLumper' && value === '')) {
            const orderData = rowState.orderData;

            if (header?.key === 'wk') {
                orderData['pickWeek'] = value;
            };

            Array.isArray(value) && value.forEach(item => {
                orderData[item.header] = item.value;
            });

            if (header?.key === 'readyDate' && !value) {
                orderData['puDate'] = '';
            };

            if (value === 'unColor') {
                orderData['orderHistoryDict'][header?.key] = false;
                orderData['outOrderHistoryDict'][header?.key] = false;
            } else
            if (header?.key === 'dropLocation') {
                orderData['dropLocationId'] = value;
            } else {
                orderData[header?.key] = value;
            }

            if (rowState.isSub) {
                const subOrderData = {
                    id: orderData.subOrderId,
                    pickInDate: resource === 'load' ? orderData.inboundPickIn : orderData.outboundPickIn,
                    pickOutDate: resource === 'load' ? orderData.inboundPickOut : orderData.outboundPickOut,
                    pickAppointmenteDate: resource === 'load' ? orderData.inboundPickAppointment : orderData.outboundPickAppointment,
                    pickAppointmentStatus: resource === 'load' ? orderData.inboundAppointmentStatus : orderData.pickOutboundAppointmentStatus,
                    dropInDate: resource === 'load' ? orderData.inboundDropIn : orderData.outboundDropIn,
                    pickWeek: resource === 'load' ? orderData.pickWeek : orderData.pickWeek,
                    dropOutDate: resource === 'load' ? orderData.inboundDropOut : orderData.outboundDropOut,
                    dropAppointmenteDate: resource === 'load' ? orderData.inboundDropAppointment : orderData.outboundDropAppointment,
                    dropAppointmentStatus: resource === 'load' ? orderData.inboundDropAppointmentStatus : orderData.outboundAppointmentStatus,
                    dueDate: orderData.dueDate,
                    puDate: orderData.puDate,
                    deliverDate: orderData.deliverDate,
                    lumper: resource === 'load' ? orderData.inboundLumper : orderData.outboundLumper,
                    dropRestriction: resource === 'load' ? orderData.pickRestriction : orderData.dropRestriction,
                    palletCount: orderData.palletCount,
                    cases: orderData.cases,
                    space: orderData.space,
                    weight: orderData.weight,
                    poNumber: orderData.poNumber,
                    stepCount: orderData.stepCount ? orderData.stepCount : 1,
                    dropLocationId: orderData.dropLocationId,
                };
                axios.put(buildUrl('suborder', ''), subOrderData, // '/api/inboudsuborder'
                    axiosConfig())
                    .then(response => {
                        if (response.data.hasError) {
                            dispatch(errorAction(resource, true, response.data.message));
                            console.error(response.data.message);
                        }
                        const headerForReload = ["palletCount", "space", "weight", "outboundLumper"];
                        const needReload = headerForReload.includes(header?.key);
                        if (Array.isArray(value) || needReload || !value) {
                            dispatch(reloadAfterUpdate(resource, 'area'));
                        }
                    }).catch(err => {
                        dispatch(errorAction(resource, true));
                        console.error(err);
                        console.log(err.response);
                    });
            }
            else {
                const url = resource === "load" ? buildUrl('order', 'loadorderitem') : buildUrl('order', 'outboundloadorderitem') 
                axios.put(url, orderData,
                    axiosConfig())
                    .then(response => {
                        if (response.data.hasError) {
                            dispatch(errorAction(resource, true, response.data.message));
                            console.error(response.data.message);
                        }
                        const headerForReload = ["palletCount", "space", "weight", "outboundLumper", "dropLocation"];
                        const needReload = headerForReload.includes(header?.key);
                        if (Array.isArray(value) || needReload || !value) {
                            dispatch(reloadAfterUpdate(resource, 'area'));
                        }
                    }).catch(err => {
                        dispatch(errorAction(resource, true));
                        console.error(err);
                        console.log(err.response);
                    });
            }
        }
    }
};

export const setItemDisplayState = (resource, rowState, header, enable) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE), data: {
        rowState,
        header,
        enable
    }
});

export const updateLoadData = (resource, newData) => ({
    type: actionTypes.getActionType(resource, actionTypes.UPDATE_LOAD_DATA),
    data: newData
});

export const updateLoadDataEFS = (resource, value, rowState, header) => ({
    type: actionTypes.getActionType(resource, actionTypes.UPDATE_EFS_DATA), data: {
        value,
        rowState,
        header
    }
});

export const setSortLoadsByCarriar = (resource, sort) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_SORT_LOAD_BY_CARRIER),
    data: sort
});

export const setSortLoadsByPickAppts = (resource, sort) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_SORT_LOAD_BY_PICK_APPTS),
    data: sort
});

export const setSortLoadsByPickLocation = (resource, sort) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_SORT_LOAD_BY_PICK_LOCATION),
    data: sort
});

export const setSortLoadsByDropLocation = (resource, sort) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_SORT_LOAD_BY_DROP_LOCATION),
    data: sort
});

export const setSortLoadsByDeliverNumber = (resource, sort) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_SORT_LOAD_BY_DELIVER_NUMBER),
    data: sort
});

export const filterInOutOrders = (resource, filterType, option) => ({
    type: actionTypes.getActionType(resource, actionTypes.FILTER_IN_OUT_ORDERS),
    data: {
        filterType,
        option,
    }
});

export const getHistoryList = (resource, url, orderId, field, isSub) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_HISTORY_LOADING) });
    const data = {
        params: {
            filterData: isSub ? {
                SubOrderId: orderId,
                field: field,
            } : 
            {
                orderId: orderId,
                field: field,
            },
        },
        headers: axiosConfig().headers,
    };
    axios.get(buildUrl(url), data, axiosConfig())
        .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_HISTORY), data: response }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
        });
};

export const setReOrderLoad = (resource, state, rowState, index) => dispatch => {
    const loadType = resource === 'load' ? 1 : 3;
    const firstOrderId = rowState.orderObjectId;
    const loadId = rowState.loadObjectId;
    const locationId = resource === 'load' ? 'pickLocationId' : 'dropLocationId';
    const orderLocationId = rowState.orderData?.[locationId];
    const loadOrders = state[resource].loadsAssignedOrders?.filter(item => item.rowState.loadObjectId === loadId);
    const ordersWithSameLocation = loadOrders.filter(order => order.rowState.orderData?.[locationId] === orderLocationId)
    const indexOfCurrentOrder = ordersWithSameLocation.findIndex(order => order.rowState.orderObjectId === firstOrderId);
    let secondOrderId = '';

    if (isNaN(index)) {
        if (index === 'up') {
            secondOrderId = ordersWithSameLocation[indexOfCurrentOrder-1].rowState.orderObjectId;
        } else {
            secondOrderId = ordersWithSameLocation[indexOfCurrentOrder+1].rowState.orderObjectId;
        }
    } else {
        secondOrderId = ordersWithSameLocation[index-1].rowState.orderObjectId;
    }

    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SET_RE_ORDER_LOAD), 
        data: { firstOrderId, secondOrderId },
    });
    axios.put(buildSimpleUrl('load/swaporders'),  {   
        firstOrderId: firstOrderId,
        secondOrderId: secondOrderId,
        loadType: loadType,
    },
    axiosConfig()
    ).then(response => {
        const errorList = response?.data?.length ? response.data?.filter(d => d.hasError) : response?.data.hasError;
        const errorMessage = response.data.message || response.data[0].message;
        errorList.length && errorMessage && dispatch(errorAction(resource, errorList.length > 0, errorMessage));
        errorList.length && errorMessage && console.error(errorMessage);
    }).catch(err => {
        dispatch(errorAction(resource, true));
        console.error(err);
        console.log(err.response);
    });
};

export const resetError = resource =>
    ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

const errorAction = (resource, reload, errorText) => dispatch => {
    if (reload) {
        axios.get(buildUrl(resource, 'area'),
            axiosConfig()
        )
            .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR), data: {
                responce, 
                errorText,
            }}))
            .catch((err) => {
                console.error(err);
                console.log(err.response);
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
            });
    }
};

const reloadAfterUpdate = (resource, endpoint) => dispatch => {
    axios.get(buildUrl(resource, endpoint),
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.LOAD), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
        });
};

