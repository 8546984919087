import moment from 'moment';
import { formatPhoneNumber } from "../../../codeless/helper/common";
export const itemDefaultSize = 20;
const toDateTime = value => value ? moment.utc(value).toDate() : '';

const extractWebLoadsToList = state => (loads) => {
    let loadsAssignedOrders = [];
    loads.forEach(loadItem => {
        loadsAssignedOrders.push(loadItem);
            loadItem.stops.forEach(stop => {
                loadsAssignedOrders.push(stop);
                    stop.ordersData.forEach(order => {
                        loadsAssignedOrders.push(order);
                    });
            })
            loadItem.subLoads.forEach(subLoad => {
                loadsAssignedOrders.push(subLoad);
                    subLoad.stops.forEach(stop => {
                        loadsAssignedOrders.push(stop);
                            stop.ordersData.forEach(order => {
                                loadsAssignedOrders.push(order);
                            });
                    })
            })
        });
    // remove loads without any stop/order
    const filteredLoadsAssignedOrders = loadsAssignedOrders
        .filter(order => order.rowState.type !== 'load' || (order.rowState.type === 'load' && order.stops?.length > 0));

    return filteredLoadsAssignedOrders;
};

export const createSubOrder = (so, originalOrder, childSubOrder) => {
    return {
        ...originalOrder,
        ...{
            parentId: so.parentId,
            orderId: so.orderId,
            subOrderId: so.id,
            loadId: so.loadId,
            originalOrderId: so.orderId,
            pickLocationId: so.dropLocationId,
            pickLocation: so.dropLocation,
            dropLocationId: childSubOrder ? childSubOrder.dropLocationId : originalOrder.dropLocationId,
            dropLocation: childSubOrder ? childSubOrder.dropLocation : originalOrder.dropLocation,
            inboundPickIn: so.pickInDate,
            inboundPickOut: so.pickOutDate,
            inboundPickAppointment: so.pickAppointmenteDate,
            inboundAppointmentStatus: so.pickAppointmentStatus,
            puDate: so.puDate,
            dueDate: so.dueDate,
            deliverDate: so.deliverDate,
            inboundDropAppointment: so.dropAppointmenteDate,
            inboundDropIn: so.dropInDate,
            inboundDropOut: so.dropOutDate,
            inboundLumper: so.lumper,
            dropRestriction: so.dropRestriction,
            pickRestriction: so.pickRestriction,
            inboundDeliveryEstimate: so.inboundDeliveryEstimate,
            pickInDate: so.pickInDate,
            pickOutDate: so.pickOutDate,
            dropInDate: so.dropInDate,
            dropOutDate: so.dropOutDate,
            outboundPickIn: so.pickInDate,
            outboundPickOut: so.pickOutDate,
            outboundDropIn: so.dropInDate,
            outboundDropOut: so.dropOutDate,
            stepCount: so.stepCount,
            carrierName: so.carrierName,
            palletCount: so.palletCount,
            cases: so.cases,
            space: so.space,
            weight: so.weight,
            poNumber: so.poNumber,
            callCheckOutAttachment: so.callCheckOutAttachment,
            outPodRequestStatus: so.outPodRequestStatus,
            orderStatusByCall: so.orderStatusByCall,
            outOrderStatusByCall: so.outOrderStatusByCall,
            outDelayedReason: so.outDelayedReason,
            outNotes: so.outNotes,
        }
    }
};

const createWebLoadArray = state => (loadsData, assignedOrders, subOrders, headers, oldLoadsAssignedOrders) => {
    //create sub orders
    const subOrdersData = [];
    subOrders.forEach(so => {
        subOrdersData.push(createSubOrder(
            so, 
            assignedOrders.find(o => o.id === so.orderId),
            subOrders.find(fso => fso.orderId === so.orderId && fso.parentId === so.id)));
    });
    const subLoads = loadsData.filter(load => load.parentLoadId);
    const loads = [];
    loadsData
        .forEach(load => loads.push(createWebLoad (
        load, 
        assignedOrders.filter(o => o.loadId === load.id || o.outboundLoadId === load.id),
        subLoads,
        subOrdersData,
        headers,
        (oldLoadsAssignedOrders || []).find(ol => ol.rowState.type === 'load' && ol.rowState.loadData.id === load.id),
        false
        )));
    if (assignedOrders.length !== loads.reduce((r, o) => r += o.ordersData.length, 0)) {
        console.log('Some assigned orders not found there loads');
    }

    // render subLoads/subOrders without Main Load
    const subOrdersOrderIDs = subOrdersData.map(subO => subO.loadId);
    const allLoadIDs = loadsData.map(load => load.id);
    if (subLoads.length > 0) {
        subLoads
            .filter(subL => subOrdersOrderIDs.includes(subL.id))
            .filter(subL => !allLoadIDs.includes(subL.parentLoadId))
            .forEach(subLoad => loads.push(createWebLoad (
                subLoad, 
                subOrdersData.filter(subOrder => subOrder.loadId === subLoad.id),
                subLoads,
                subOrdersData,
                headers,
                (oldLoadsAssignedOrders || []).find(ol => ol.rowState.type === 'load' && ol.rowState.loadData.id === subLoad.id),
                true
            )));
    }
    return loads;
};

export const updateOrdersLoadState = (state, orders, loads, subOrders, hasError = false, message = '', filterType = 'all') => ({
    ...state, ...{
        orders,
        loads,
        subOrders,
        loading: false,
        hasError,
        message,
        filterType,
        loadsAssignedOrders:
            extractWebLoadsToList(state) (
                createWebLoadArray(state) (
                    loads,
                    orders,
                    subOrders,
                    state.headers,
                    state.loadsAssignedOrders), state.headers),
    }
});

export const loadContent = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    const filterType = state.filterType ? state.filterType : 'all';
    return updateOrdersLoadState(state, [...data.assignedOrders.data], data.loads.data, data.subOrders.data, hasError, message, filterType);
};

export const createWebLoad = (loadData, loadOrders, subLoads, subOrdersData, headers, oldWebLoad, isSub) => {
    const loadId = `load_${loadData.id}`;
    const loadWebData = {
        row: [],
        ordersData: [],
        subLoads: [],
        stops: [],
        rowState: {
            type: 'load',
            isSub,
            subId: subLoads ? subLoads.find(l => l.parentLoadId === loadData.id)?.id : null,
            id: loadId,
            loadId: loadData.id,
            loadData,
            visible: true,
            reacted: oldWebLoad !== undefined && oldWebLoad.rowState.reacted,
            isChecked: oldWebLoad !== undefined && oldWebLoad.rowState.reacted,
            available: false,
            createPickKey: order => {
                let createKey = '0';
                if (order.loadId) {
                    createKey = `${order.loadId}_${order.pickLocationId}`;
                }
                return createKey;
            },
            createDropKey: order => {
                let createKey = '0';
                if (order.loadId) {
                    createKey = `${order.loadId}`;
                    if (order.warehouse) {
                        createKey = `${createKey}_0_${order.warehouseId}`;
                    }
                    else {
                        createKey = `${createKey}_${order.dropLocationId}_0`;
                    }
                }
                return createKey;
            },
            groups: (orders, keyFunction) =>  orders.reduce((r, o) => {
                    const groupBy = keyFunction(o.rowState.orderData);
                    r[groupBy] = [...r[groupBy] || [], o.rowState.orderData];
                    return r;
                }, {}),
            findOrderGroup: (groups, order, createKey) => {
                return Object.keys(groups).find(go => {
                    const oG = groups[go];
                    if (oG.some(e => e.id === order.id)) {
                        return groups[go];
                    }
                    if (createKey && go === createKey(order)) {
                        return groups[go];
                    }
                    return null;
                });
            },
            height: itemDefaultSize
        }
    };
    loadOrders.forEach(lo => loadWebData.ordersData.push(createWebOrder(lo, headers, loadData, loadId, loadWebData.rowState.reacted, '', isSub, subOrdersData)));
    loadWebData.ordersData =
        loadWebData.ordersData.sort((a, b) => new Date(a.rowState.orderData.inboundPickAppointment) - new Date(b.rowState.orderData.inboundPickAppointment ?? ''));

    const pickInboundGroups = loadWebData.rowState.groups(loadWebData.ordersData, loadWebData.rowState.createPickKey);
    let stopNumber = 0;

    Object.keys(pickInboundGroups).forEach(key => {
        stopNumber++;
        const groupOrders = pickInboundGroups[key];
        groupOrders.forEach(o => {
            loadWebData.ordersData.find(wo => wo.rowState.orderData.id === o.id).row.find(f => f.key === 'left_action').value = stopNumber;
        });
    });

    const stopsList  =  loadData?.loadType === 1 ? [...new Set(loadOrders.map(order => order.pickLocationId))] :
                        loadData?.loadType === 3 ? [...new Set(loadOrders.map(order => order.dropLocationId))] : '';
    stopsList.forEach((stopNumber, i) => {
        loadWebData.stops.push(createStop(loadOrders, headers, loadData, loadId, loadWebData?.rowState.reacted, stopNumber, stopsList));
    });

    loadWebData.ordersData =
        loadWebData.ordersData.sort((a, b) => +a.row.find(f => f.key === 'left_action').value - +b.row.find(f => f.key === 'left_action').value);
    
    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
        const headerItem = headers[hederIndex];
        let value = null;
        let fieldType = 'text';
        let editable = false;
        let cellStyle = {};
        let tooltip = null;
        if (!headerItem.leftAction) {        
            switch (headerItem.key) {
                case 'palletCount':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    fieldType = 'text';
                    break;
                case 'cases':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    fieldType = 'text';
                    break;
                case 'weight':
                    if (loadWebData.ordersData.length > 0) {
                        value = loadWebData.ordersData.reduce((a, b) => {
                            return a + (b ?
                                +b.row.find(f => f.key === headerItem.key).value : 0);
                        }, 0);
                    }
                    fieldType = 'text';
                    break;
                case 'loadNumber':
                    value = loadData.loadNumber;
                    fieldType = 'link';
                    break;
                case 'carrierName':
                    value = loadData.carrierName;
                    fieldType = 'text';
                    break;
                case 'driverName':
                    value = loadData.driverName;
                    break;
                case 'driverPhone':
                    value = formatPhoneNumber(loadData.driverPhone);
                    break;
                case 'loadCallCheckNote':
                    value = loadData.loadCommentsCount ? loadData.loadCommentsCount : 0;
                    break;
                // case 'loadPositionType':
                //     value = loadData.loadPositionType;
                //     break;
                case 'delivered':
                    value = 'text';
                    fieldType = 'button';
                    break;
                default:
                    break;
            }
        }
        else {
            value = '';
            fieldType = 'text';
        }

        loadWebData.row.push({
            key: headerItem.key,
            value,
            fieldType,
            displayState: 1,
            editable,
            cellStyle,
            tooltip
        });
    }

    if (subLoads) {
        subLoads
        .filter(sl => sl.parentLoadId === loadData.id)
        .forEach(sl => {
            loadWebData.subLoads.push(createWebLoad(
                sl, 
                subOrdersData.filter(so => so.loadId === sl.id),
                subLoads, 
                subOrdersData, 
                headers, 
                oldWebLoad, 
                true));
        });
    }
    return loadWebData;
};

export const createStop = (loadOrders, headers, loadData, loadId, isHidden, stopNumber, stopsList) => {
    const stop = loadData.stops.filter(stop => stop.locationId === stopNumber);
    const loadType = loadData.loadType;
    const stopData = {
        row: [],
        ordersData: [],
        rowState: {
            type: 'stop',
            id: `stop_${loadData.id}_${stopNumber}`,
            loadData,
            firstOrderData: {},
            loadId: loadId && loadId,
            visible: !isHidden,
            reacted: false,
            available: loadData === null || !loadData?.id,
            height: itemDefaultSize,
            stopNumber,
            notes: stop?.[0]?.notes,
        }
    };

    loadOrders.forEach(lo => {
        if (loadType === 1 && lo.pickLocationId === stopNumber) {
            stopData.ordersData.push(createWebOrder(lo, headers, loadData, loadId, stopData.rowState.reacted, stopData.rowState.id));
            stopData.ordersData.forEach(order => order.row.find(row => row.key === 'left_action').value = stopsList.indexOf(stopNumber)+1)
        } else if (loadType === 3 && lo.dropLocationId === stopNumber) {
            stopData.ordersData.push(createWebOrder(lo, headers, loadData, loadId, stopData.rowState.reacted, stopData.rowState.id));
            stopData.ordersData.forEach(order => order.row.find(row => row.key === 'left_action').value = stopsList.indexOf(stopNumber)+1)
        }
    });

    stopData.ordersData =
        stopData.ordersData.sort((a, b) => new Date(a.rowState.orderData.inboundPickAppointment) - new Date(b.rowState.orderData.inboundPickAppointment ?? ''));

    stopData.rowState.firstOrderData = stopData.ordersData[0].rowState.orderData;
    const firstOrderData = stopData.ordersData[0].rowState.orderData;

    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
        const headerItem = headers[hederIndex];
        let value = null;
        let value2 = null;
        let fieldType = 'text';
        let editable = false;
        let cellStyle = {};
        let tooltip = null;

        if (!headerItem.leftAction) {
            switch (headerItem.key) {
                case 'pickInDate':
                    value = loadData.loadType === 1 ? toDateTime(firstOrderData.inboundPickIn): loadData.loadType === 3 ? toDateTime(firstOrderData.outboundPickIn) : '';
                    fieldType = 'dateTime';
                    break;
                case 'pickOutDate':
                    value = loadData.loadType === 1 ? toDateTime(firstOrderData.inboundPickOut): loadData.loadType === 3 ? toDateTime(firstOrderData.outboundPickOut) : '';
                    fieldType = 'dateTime';
                    break;
                case 'dropInDate':
                    value = loadData.loadType === 1 ? toDateTime(firstOrderData.inboundDropIn): loadData.loadType === 3 ? toDateTime(firstOrderData.outboundDropIn) : '';
                    fieldType = 'dateTime';
                    break;
                case 'dropOutDate':
                    value = loadData.loadType === 1 ? toDateTime(firstOrderData.inboundDropOut): loadData.loadType === 3 ? toDateTime(firstOrderData.outboundDropOut) : '';
                    fieldType = 'dateTime';
                    break;
                case 'warehouse':
                    value = loadData.loadType === 1 ? firstOrderData.pickLocation: loadData.loadType === 3 ? firstOrderData.dropLocation : '';
                    fieldType = 'text';
                    break;
                // case 'dropLocation':
                //     value = 'San Fernando, CA (TST)'; //??? need to change
                //     fieldType = 'text';
                //     break;
                case 'inboundPickAppointment':
                    value = loadData.loadType === 1 ? toDateTime(firstOrderData.inboundPickAppointment): loadData.loadType === 3 ? toDateTime(firstOrderData.outboundPickAppointment) : '';
                    fieldType = 'dateTime';
                    break;
                case 'palletCount':
                    value = stopData.ordersData.reduce((acc, order) => {
                        return acc + order.rowState.orderData.palletCount
                    }, 0) || '';
                    fieldType = 'text';
                    break;
                case 'cases':
                    value = stopData.ordersData.reduce((acc, order) => {
                        return acc + order.rowState.orderData.cases
                    }, 0) || '';
                    fieldType = 'text';
                    break;
                case 'weight':
                    value = stopData.ordersData.reduce((acc, order) => {
                        return acc + order.rowState.orderData.weight
                    }, 0) || '';
                    fieldType = 'text';
                    break;
                case 'loadNumber':
                    value = loadData ? loadData.loadNumber : '';
                    fieldType = 'link';
                    break;
                case 'lumper_efs':
                    value = loadData.loadType === 1 ? firstOrderData.pickWhLumper : firstOrderData.dropWhLumper;
                    value2 = loadData.loadType === 1 ? firstOrderData.pickWhEfs : firstOrderData.dropWhEfs;
                    fieldType = 'text';
                    break;
                case 'loadCallCheckNote':
                    value = stopData.rowState.notes;
                    fieldType = 'textInput';
                    editable = true;
                    break;
                case 'orderStatusByCall':
                    value = loadData.loadType === 1 ? firstOrderData.inOrderStatusByCall : loadData.loadType === 3 ? firstOrderData.outOrderStatusByCall : '';
                    editable = true;
                    fieldType = 'select';
                    break;
                case 'delayedReason':
                    value = loadData.loadType === 1 ? firstOrderData.inDelayedReason : loadData.loadType === 3 ? firstOrderData.outDelayedReason : '';
                    editable = true;
                    fieldType = 'select';
                    break;
                default:
                    break;
            }
        }
        else {
            value = '';
            value2 = '';
            fieldType = 'text';
        }

        stopData.row.push({
            key: headerItem.key,
            value,
            value2,
            fieldType,
            displayState: 1,
            editable,
            cellStyle,
            tooltip
        });
    }

    return stopData;
};

const findFirstSubOrder = (selfId, subOrders, originalOrderId) =>
    subOrders.find(o => o.originalOrderId === originalOrderId && o.parentId === selfId);

export const createWebOrder = (oData, headers, loadData, loadId, isHidden, stopId, isSub, subOrders) => {
    const orderData = { ...oData };
    let allowCreateSubOrder = false;
    let firstSubOrder = null;
    if (!isSub && loadData !== null) {
        firstSubOrder = subOrders ? subOrders.find(o => o.originalOrderId === orderData.id && !o.parentId) : null;
        allowCreateSubOrder = firstSubOrder === undefined;
        if (firstSubOrder) {
            orderData.dropLocationId = firstSubOrder.pickLocationId;
            orderData.dropLocation = firstSubOrder.pickLocation;
        }
    }
    else if (isSub && subOrders) {
        firstSubOrder = findFirstSubOrder(orderData.subOrderId, subOrders, orderData.id);
        allowCreateSubOrder = firstSubOrder === undefined;
    }

    const orderWebData = {
        row: [],
        rowState: {
            type: 'order',
            isSub,
            subId: firstSubOrder && firstSubOrder.id,
            id: `${(isSub ? `sub_${orderData.parentId ? orderData.parentId : ''}` : '')}order_${orderData.id}`,
            orderObjectId: orderData.id,
            loadObjectId: loadData && loadData.id,
            orderData: orderData,
            loadData,
            orderId: `order_${orderData.id}`,
            loadId: loadId && loadId,
            stopId: stopId && stopId,
            visible: !isHidden,
            reacted: false,
            available: loadData === null || !loadData.id,
            height: itemDefaultSize,
            allowCreateSubOrder,
        }
    };

    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
        const headerItem = headers[hederIndex];
        let value = null;
        let value2 = null;
        let fieldType = 'text';
        let editable = false;
        let cellStyle = {};
        let tooltip = null;
        if (!headerItem.leftAction) {
            switch (headerItem.key) {
                case 'id':
                    value = orderData.id;
                    break;
                case 'inboundDeliveryEstimate':
                    value = toDateTime(orderData.inboundDeliveryEstimate);
                    fieldType = 'dateTime';
                    editable = loadData.loadType === 1 ? true : false;
                    break;
                case 'inboundPickAppointment':
                    value = loadData.loadType === 1 ? toDateTime(orderData.inboundPickAppointment): loadData.loadType === 3 ? toDateTime(orderData.outboundPickAppointment) : '';
                    fieldType = 'dateTime';
                    break;
                case 'pickInDate':
                    value = loadData.loadType === 1 ? toDateTime(orderData.inboundPickIn): loadData.loadType === 3 ? toDateTime(orderData.outboundPickIn) : '';
                    fieldType = 'dateTime';
                    editable = true;
                    break;
                case 'pickOutDate':
                    value = loadData.loadType === 1 ? toDateTime(orderData.inboundPickOut): loadData.loadType === 3 ? toDateTime(orderData.outboundPickOut) : '';
                    fieldType = 'dateTime';
                    editable = true;
                    break;
                case 'dueDate':
                    value = toDateTime(orderData.dueDate);
                    fieldType = 'dateTime';
                    break;
                case 'inDelAppt':
                    value = toDateTime(orderData.inboundDropAppointment);
                    fieldType = 'dateTime';
                    break;
                case 'dropInDate':
                    value = loadData.loadType === 1 ? toDateTime(orderData.inboundDropIn): loadData.loadType === 3 ? toDateTime(orderData.outboundDropIn) : '';
                    fieldType = 'dateTime';
                    editable = true;
                    break;
                case 'dropOutDate':
                    value = loadData.loadType === 1 ? toDateTime(orderData.inboundDropOut): loadData.loadType === 3 ? toDateTime(orderData.outboundDropOut) : '';
                    fieldType = 'dateTime';
                    editable = true;
                        break;
                case 'customerName':
                    value = orderData.customerName;
                    fieldType = 'text';
                    break;
                case 'deliverDate':
                    value = toDateTime(orderData.deliverDate);
                    editable = true;
                    fieldType = 'date';
                    break;
                case 'puDate':
                    value = toDateTime(orderData.puDate);
                    editable = true;
                    fieldType = 'date';
                    break;
                // case 'pickLocation':
                //     value = orderData.pickLocation;
                //     fieldType = 'text';
                //     break;
                // case 'dropLocation':
                //     value = orderData.dropLocation;
                //     fieldType = 'text';
                //     break;
                case 'warehouse':
                    value = loadData.loadType === 1 ? orderData.pickLocation: loadData.loadType === 3 ? orderData.dropLocation : '';
                    fieldType = 'text';
                    break;
                // case 'inboundDropAppointment':
                //     value = loadData.loadType === 1 ? toDateTime(orderData.inboundDropAppointment): loadData.loadType === 3 ? toDateTime(orderData.outboundDropAppointment) : '';
                //     fieldType = 'dateTime';
                //     break;
                case 'poNumber':
                    value = orderData.poNumber;
                    value2 = orderData.stepCount;
                    editable = orderData.stepCount ? true : false;
                    fieldType = 'textInputPO';
                    break;
                case 'soNumber':
                    value = orderData.soNumber;
                    fieldType = 'textInput';
                    break;
                case 'palletCount':
                    value = orderData.palletCount;
                    fieldType = 'integer';
                    break;
                case 'cases':
                    value = orderData.cases;
                    fieldType = 'integer';
                    break;
                case 'weight':
                    value = orderData.weight;
                    fieldType = 'integer';
                    break;
                case 'loadPositionType':
                    value = loadData.loadType === 1 ? orderData.pickRestriction : orderData.dropRestriction;
                    break;
                case 'loadNumber':
                    value = loadData ? loadData.loadNumber : '';
                    fieldType = 'link';
                    break;
                case 'podRequestStatus':
                    value = loadData.loadType === 1 ? orderData.inPodRequestStatus : loadData.loadType === 3 ? orderData.outPodRequestStatus : '';
                    editable = true;
                    fieldType = 'select';
                    tooltip = loadData.loadType === 1 && orderData.inPodRequestAuthorFullName.trim() ?
                                `Last Changed: ${orderData.inPodRequestAuthorFullName}` :
                                loadData.loadType === 1 && !orderData.inPodRequestAuthorFullName.trim() ?
                                `No changes` :
                                loadData.loadType === 3 && orderData.outPodRequestAuthorFullName.trim() ?
                                `Last Changed: ${orderData.outPodRequestAuthorFullName}` :
                                loadData.loadType === 3 && !orderData.outPodRequestAuthorFullName.trim() ?
                                `No changes` : '';
                    break;
                case 'podDoc':
                    value = loadData.loadType === 1 ? orderData.callCheckInAttachment?.fileName : loadData.loadType === 3 ? orderData.callCheckOutAttachment?.fileName : '';
                    editable = true;
                    fieldType = 'upload';
                    tooltip = loadData.loadType === 1 && orderData.callCheckInAttachment?.fullName ?
                        `Last Changed: ${orderData.callCheckInAttachment?.fullName}` :
                        loadData.loadType === 1 && !orderData.callCheckInAttachment?.fullName ?
                        `No changes` :
                        loadData.loadType === 3 && orderData.callCheckOutAttachment?.fullName ?
                        `Last Changed: ${orderData?.callCheckOutAttachment?.fullName}` :
                        loadData.loadType === 3 && !orderData.callCheckOutAttachment?.fullName ?
                        `No changes` : '';
                    break;
                case 'orderNotesByCall':
                    value = loadData.loadType === 1 ? orderData.inNotes : loadData.loadType === 3 ? orderData.outNotes : '';
                    value2 = loadData.loadType === 1 ? orderData.inPodNotesHistory : loadData.loadType === 3 ? orderData.outPodNotesHistory : '';
                    fieldType = 'select_and_popover';
                    editable = true;
                    break;
                case 'actions':
                    value = 'text';
                    fieldType = 'actions';
                    break;
                default:
                    break;
            }
        }
        else {
            value = '';
            fieldType = 'text';
        }

        orderWebData.row.push({
            key: headerItem.key,
            value,
            value2,
            fieldType,
            displayState: 1,
            editable,
            cellStyle,
            tooltip
        });
    }

    return orderWebData;
};

export const setHeaderWidth = (state, ind, width) => {
    const header = state.headers[ind];
    header.width = width;
    return {
        ...state, ...{
            headers: [
                ...state.headers.slice(0, ind),
                ...[header],
                ...state.headers.slice(ind + 1)
            ],
            loadsAssignedOrders: state.loadsAssignedOrders,
        }
    };
};

export const filterCallChecks = (state, type) => {
    return {
        ...state,
        ...{
            filterType: type,
        },
    }
};

export const reactedAssigned = (state, type, id) => {
    const loadsAssignedOrders = state.loadsAssignedOrders;
    const index = loadsAssignedOrders.findIndex(i => i.rowState.id === id && i.rowState.type === type);
    const item = loadsAssignedOrders[index];

    if (item) {
        item.rowState.reacted = !item.rowState.reacted;
        if (type === 'load') {
            const stops = loadsAssignedOrders.filter(i => i.rowState.loadId === id && i.rowState.type === 'stop');
            const orders = loadsAssignedOrders.filter(i => i.rowState.loadId === id && i.rowState.type === 'order');
            orders.forEach(o => o.rowState.visible = !o.rowState.visible);

            stops.forEach(stop => {
                if (stop.rowState.visible) {
                    orders.forEach(o => o.rowState.visible = false);
                    stop.rowState.visible = false;
                } else {
                    orders.forEach(o => o.rowState.visible = true);
                    stop.rowState.visible = true;
                }
            })
            return {
                ...state, ...{
                    headers: state.headers,
                    loadsAssignedOrders:
                    [
                        ...state.loadsAssignedOrders.slice(0, index),
                        ...[item],
                        ...state.loadsAssignedOrders.slice(index + 1)
                    ],
                }
            }
        }
        if (type === 'stop') {
            const stopOrders = state.loadsAssignedOrders.filter(i => i.rowState.stopId === id && i.rowState.type === 'order');
            stopOrders.forEach(o => o.rowState.visible = !o.rowState.visible);
            return {
                ...state, ...{
                    headers: state.headers,
                    loadsAssignedOrders:
                    [
                        ...state.loadsAssignedOrders.slice(0, index),
                        ...[item],
                        ...state.loadsAssignedOrders.slice(index + 1)
                    ],
                }
            }
        }
    }
    return state;
};

export const updateLoadOrderGroupData = (state, loadData, changedOrder, header, newValue, newOrders, isSub) => {
    if (!isSub) {
        let stopsOrders;
        let orderLoad;
        const orderStops = state.loadsAssignedOrders.filter(order => order.rowState?.orderData?.id === changedOrder?.id);
        const changedOrderLoadType = loadData.loadType;
        const orderStopId = orderStops.find(stop => stop.rowState.loadData.loadType === changedOrderLoadType)?.rowState?.stopId;
        
        const stopOrdersState = state.loadsAssignedOrders.filter(order => order.rowState.stopId === orderStopId);

        const stopOrdersIds = stopOrdersState.map(order => order.rowState.orderData?.id);
        stopsOrders = newOrders.filter(order => stopOrdersIds.includes(order.id));
        orderLoad = state.loadsAssignedOrders.find(l => l.rowState.type === 'load' && l.rowState.loadData.id === loadData.id);

        if (orderLoad) {
            stopsOrders.forEach(order => {
                if (header.key === 'pickInDate') {
                    if (orderLoad.rowState.loadData.loadType === 3) {
                        order['outboundPickIn'] = newValue;
                    } else if (orderLoad.rowState.loadData.loadType === 1) {
                        order['inboundPickIn'] = newValue;
                    }
                } else
                if (header.key === 'pickOutDate') {
                    if (orderLoad.rowState.loadData.loadType === 3) {
                        order['outboundPickOut'] = newValue;
                    } else if (orderLoad.rowState.loadData.loadType === 1) {
                        order['inboundPickOut'] = newValue;
                    }
                } else
                if (header.key === 'dropInDate') {
                    if (orderLoad.rowState.loadData.loadType === 3) {
                        order['outboundDropIn'] = newValue;
                    } else if (orderLoad.rowState.loadData.loadType === 1) {
                        order['inboundDropIn'] = newValue;
                    }
                } else
                if (header.key === 'dropOutDate') {
                    if (orderLoad.rowState.loadData.loadType === 3) {
                        order['outboundDropOut'] = newValue;
                    } else if (orderLoad.rowState.loadData.loadType === 1) {
                        order['inboundDropOut'] = newValue;
                    }
                } else
                if (header.key === 'orderStatusByCall') {
                    if (orderLoad.rowState.loadData.loadType === 3) {
                        order['outOrderStatusByCall'] = newValue;
                        if (newValue === 0 || newValue === 1) {
                            order['outDelayedReason'] = null;
                        }
                    } else if (orderLoad.rowState.loadData.loadType === 1) {
                        if (newValue === 0 || newValue === 1) {
                            order['inDelayedReason'] = null;
                        }
                        order['inOrderStatusByCall'] = newValue;
                    }
                } else
                if (header.key === 'delayedReason') {
                    if (orderLoad.rowState.loadData.loadType === 3) {
                        order['outDelayedReason'] = newValue;
                    } else if (orderLoad.rowState.loadData.loadType === 1) {
                        order['inDelayedReason'] = newValue;
                    }
                }
                order[header.key] = newValue;
            });
        }
    } else {
        if (changedOrder) {
            if (header.key === 'podRequestStatus') {
                changedOrder['outPodRequestStatus'] = newValue;
            } else {
                changedOrder[header?.key] = newValue;
            }
        }
    }
};

export const setData = (state, newValue, rowState, header) => {
    if (rowState.orderData || rowState.firstOrderData) {
        const newOrders = [...state.orders];
        const isSub = rowState.orderData?.subOrderId;
        const isStop = rowState.firstOrderData;
        const stopSub = rowState?.firstOrderData?.subOrderId;
        let changedOrder;

        if (isStop && !stopSub) {
            changedOrder = newOrders.find(o => o.id === rowState.firstOrderData.id);
        } else if (!isSub && !stopSub) {
            changedOrder = newOrders.find(o => o.id === rowState.orderData.id);
        };
        if (changedOrder) {
            const subOrders = [...state.subOrders];
            const subOrdersOfChangedOrder = subOrders.filter(sub => sub.orderId === changedOrder.id)

            if (header.key === 'orderStatusByCall') {
                if (rowState.loadData.loadType === 3) {
                    changedOrder['outOrderStatusByCall'] = newValue;
                    if (newValue !== 2) {
                        changedOrder['outDelayedReason'] = null;
                    }
                } else if (rowState.loadData.loadType === 1) {
                    changedOrder['inOrderStatusByCall'] = newValue;
                    if (newValue !== 2) {
                        changedOrder['inDelayedReason'] = null;
                    }
                }
            } else if (header.key === 'delayedReason') {
                if (rowState.loadData.loadType === 3) {
                    changedOrder['outDelayedReason'] = newValue;
                } else if (rowState.loadData.loadType === 1) {
                    changedOrder['inDelayedReason'] = newValue;
                }
            } else if (header.key === 'orderNotesByCall') {
                if (rowState.loadData.loadType === 3) {
                    changedOrder['outNotes'] = newValue;
                } else if (rowState.loadData.loadType === 1) {
                    changedOrder['inNotes'] = newValue;
                }
            } else if (header.key === 'podRequestStatus') {
                if (newValue === 3 && rowState.loadData.loadType === 3) {
                    subOrdersOfChangedOrder.forEach(subOrder => {
                        subOrder['outPodRequestStatus'] = newValue;
                    })
                } else if (changedOrder['outPodRequestStatus'] === 3 && newValue !== 3 && rowState.loadData.loadType === 3) {
                    subOrdersOfChangedOrder.forEach(subOrder => {
                        subOrder['outPodRequestStatus'] = 0;
                    })
                } 
                if (rowState.loadData.loadType === 3) {
                    changedOrder['outPodRequestStatus'] = newValue;
                } else if (rowState.loadData.loadType === 1) {
                    changedOrder['inPodRequestStatus'] = newValue;
                }
                // remove uploaded file if we change the status into "Don't split" from Received and order doesn't have sub
                if (newValue === 3) {
                    if (rowState.loadData.loadType === 3) {
                        changedOrder['callCheckOutAttachment'] = null;
                    } else if (rowState.loadData.loadType === 1) {
                        changedOrder['callCheckInAttachment'] = null;
                    }
                }
                if (rowState.loadData.loadType === 3 && ((newValue === 0 || newValue === 2) && (rowState?.orderData?.outPodRequestStatus === 1 || rowState?.orderData?.outPodRequestStatus === 3))) {
                    changedOrder['callCheckOutAttachment'] = null;
                }
                if (rowState.loadData.loadType === 1 && ((newValue === 0 || newValue === 2) && (rowState?.orderData?.inPodRequestStatus === 1 || rowState?.orderData?.inPodRequestStatus === 3))) {
                    changedOrder['callCheckInAttachment'] = null;
                }
            } else if (header.key === 'pickInDate') {
                if (isSub) {
                    changedOrder['pickInDate'] = newValue;
                } else {
                    if (rowState.loadData.loadType === 3) {
                        changedOrder['outboundPickIn'] = newValue;
                    } else if (rowState.loadData.loadType === 1) {
                        changedOrder['inboundPickIn'] = newValue;
                    }
                }
            } else if (header.key === 'pickOutDate') {
                if (isSub) {
                    changedOrder['pickOutDate'] = newValue;
                } else {
                    if (rowState.loadData.loadType === 3) {
                    } else if (rowState.loadData.loadType === 1) {
                        changedOrder['inboundPickOut'] = newValue;
                    }
                }
            } else if (header.key === 'dropInDate') {
                if (isSub) {
                    changedOrder['dropInDate'] = newValue;
                } else {
                    if (rowState.loadData.loadType === 3) {
                        changedOrder['outboundDropIn'] = newValue;
                    } else if (rowState.loadData.loadType === 1) {
                        changedOrder['inboundDropIn'] = newValue;
                    }
                }
            } else if (header.key === 'dropOutDate') {
                if (isSub) {
                    changedOrder['dropOutDate'] = newValue;
                } else {
                    if (rowState.loadData.loadType === 3) {
                        changedOrder['outboundDropOut'] = newValue;
                    } else if (rowState.loadData.loadType === 1) {
                        changedOrder['inboundDropOut'] = newValue;
                    }
                }
            } else if (header.key === 'podDoc') {
                if (rowState.loadData.loadType === 3) {
                    changedOrder['callCheckOutAttachment'] = newValue;
                } else if (rowState.loadData.loadType === 1) {
                    changedOrder['callCheckInAttachment'] = newValue;
                }
            } else if (header.key === 'loadCallCheckNote') {
                if (rowState.loadData.loadType === 3) {
                    const stop = rowState.loadData.stops.filter(stop => stop.locationId === changedOrder.dropLocationId);
                    if (stop && stop[0]) {
                        stop[0].notes = newValue;
                    } else if (!stop[0]) {
                        rowState.loadData.stops.push({ notes: newValue, locationId: changedOrder.dropLocationId })
                    }
                } else if (rowState.loadData.loadType === 1) {
                    const stop = rowState.loadData.stops.filter(stop => stop.locationId === changedOrder.pickLocationId);
                    if (stop && stop[0]) {
                        stop[0].notes = newValue;
                    } else if (!stop[0]) {
                        rowState.loadData.stops.push({ notes: newValue, locationId: changedOrder.pickLocationId })
                    }
                }
            } else changedOrder[header.key] = newValue;
        }

        //sub order set
        const newSubOrders = [...state.subOrders];
        let changedSubOrder;
        if (rowState.orderData?.subOrderId) {
            changedSubOrder = newSubOrders.find(o => o.id === rowState.orderData?.subOrderId)
            || newSubOrders.find(o => o.orderId === rowState.orderData?.id);
        // when update the stop
        } else if (rowState.firstOrderData?.subOrderId) {            
            changedSubOrder = newSubOrders.find(o => o.id === rowState.firstOrderData?.subOrderId)
            || newSubOrders.find(o => o.orderId === rowState.firstOrderData?.id);
        }

        if (changedSubOrder) {
            if (header.key === 'podRequestStatus') {
                if (rowState.loadData.loadType === 3) {
                    changedSubOrder['outPodRequestStatus'] = newValue;
                } else if (rowState.loadData.loadType === 1) {
                    changedSubOrder['inPodRequestStatus'] = newValue;
                }
                // remove uploaded file if we change the status into "Don't split" from Received and order doesn't have sub
                if (newValue === 3) {
                    if (rowState.loadData.loadType === 3) {
                        changedSubOrder['callCheckOutAttachment'] = null;
                    } else if (rowState.loadData.loadType === 1) {
                        changedSubOrder['callCheckInAttachment'] = null;
                    }
                }
                if (rowState.loadData.loadType === 3 && ((newValue === 0 || newValue === 2) && (rowState?.orderData?.outPodRequestStatus === 1 || rowState?.orderData?.outPodRequestStatus === 3))) {
                    changedSubOrder['callCheckOutAttachment'] = null;
                }
                if (rowState.loadData.loadType === 1 && ((newValue === 0 || newValue === 2) && (rowState?.orderData?.inPodRequestStatus === 1 || rowState?.orderData?.inPodRequestStatus === 3))) {
                    changedSubOrder['callCheckInAttachment'] = null;
                }
            } else if (header.key === 'podDoc') {
                changedSubOrder['callCheckOutAttachment'] = newValue;
                changedSubOrder['callCheckAttachment'] = newValue;
            } else if (header.key === 'orderStatusByCall') {
                if (rowState.loadData.loadType === 3) {
                    changedSubOrder['outOrderStatusByCall'] = newValue;
                    if (newValue !== 2) {
                        changedSubOrder['outDelayedReason'] = null;
                    }
                }
            } else if (header.key === 'orderNotesByCall') {
                if (rowState.loadData.loadType === 3) {
                    changedSubOrder['outNotes'] = newValue;
                    changedSubOrder['orderNotesByCall'] = newValue;
                }
            } else if (header.key === 'delayedReason') {
                changedSubOrder['outDelayedReason'] = newValue;
            } else {
                changedSubOrder[header?.key] = newValue;
            }
        }
        
        updateLoadOrderGroupData(state, rowState?.loadData, changedOrder, header, newValue, newOrders, isSub);
        return updateOrdersLoadState(state, newOrders, state.loads, newSubOrders, state.hasError, state.message, state.filterType);
    }
    else {
        return state;
    }
};

export const getHistoryListCallCheck = (state, response) => {
    const data = response.data.data || [];
    return {
        ...state,
        historyList: data,
        historyLoading: false,
    }
};

export const setHistoryLoadingCallCheck = state => ({
    ...state, ...{
        historyLoading: true
    }
});

export const setDisplayState = (state, rowState, header, enable) => ({
    ...state, ...{
        lastSelected: {
            id: rowState.id,
            headerKey: header.key,
            enable: enable
        }
    }
});

export const setError = (state, err) => ({
    ...state,
    ...{
        hasError: true,
        message: 'Something went wrong, please try again',
    },
});

export const resetError = state => ({
    ...state,
    ...{
        hasError: false,
        message: ''
    }
});

export const setInLoading = state => ({
    ...state, ...{
        loading: true
    }
});
